import React from "react";
import { About } from "./style";

function AboutMe() {
  return (
    <About.Wrapper>
      <About.Container>
        <About.Main>
          <About.Col1>
            <About.Img alt="Mahmoud Sallam" src="/images/formal3.png" />
          </About.Col1>
          <About.Col2>
            <About.Heading>About me</About.Heading>
            <About.Desc>
              I'm a Software Engineer and Product Designer (UX Engineer) based
              in Egypt with experience in delivering end-to-end UX/UI design for
              software products and leveraging JavaScript to build responsive
              websites, web apps, and interactive features that drive business
              growth and improve the user experience.
            </About.Desc>
            <About.Desc>
              I'm a big-picture thinker and passionate about building digital
              products that improve the lives of people starting from concept to
              research to design to implementation.
            </About.Desc>
            <About.Desc>
              My Computer Engineering and Design background enables me to bridge
              the gap between engineers and designers and consider their needs
              when making design decisions.
            </About.Desc>
            <About.Desc>
              Being a mentor at udacity helps me to create an open and
              supportive climate for discussion between engineers and designers
              as well as communicate my ideas clearly and concisely.
            </About.Desc>
            <About.Desc>
              I love talking to motivated and ambitious people who are unafraid
              of sharing their crazy ideas with the world.
            </About.Desc>
          </About.Col2>
          <About.Col3>
            {" "}
            <About.SubCol>
              <About.Heading>Current</About.Heading>
              <About.Items>
                <About.Item> UX Session Lead at Udacity</About.Item>
                <About.Item> Software Engineer at IST Networks</About.Item>
              </About.Items>
            </About.SubCol>
            <About.SubCol>
              <About.Heading>Research</About.Heading>
              <About.Items>
                <About.Item> Surveys</About.Item>
                <About.Item> Interviews</About.Item>
                <About.Item> Personas</About.Item>
                <About.Item> Affinity Mapping</About.Item>
                <About.Item> Usability Testing</About.Item>
                <About.Item> A/B Testing</About.Item>
              </About.Items>
            </About.SubCol>
            <About.SubCol>
              <About.Heading>Design</About.Heading>
              <About.Items>
                <About.Item> ِAdobe Photoshop</About.Item>
                <About.Item> ِAdobe XD</About.Item>
                <About.Item> ِAdobe Illustrator</About.Item>
                <About.Item> Figma</About.Item>
                <About.Item> Wireframing </About.Item>
                <About.Item> Interactive Prototyping</About.Item>
              </About.Items>
            </About.SubCol>
            <About.SubCol>
              <About.Heading>Development</About.Heading>
              <About.Items>
                <About.Item> ِHTML/CSS</About.Item>
                <About.Item> ِJavaScript</About.Item>
                <About.Item> Python</About.Item>
                <About.Item> C++</About.Item>
                <About.Item> React.js</About.Item>
                <About.Item> Responsive Design</About.Item>
                <About.Item> Styled Components</About.Item>
                <About.Item> Material UI</About.Item>
                <About.Item> Bootstrap</About.Item>
                <About.Item> Tailwind CSS</About.Item>
                <About.Item> GraphQL</About.Item>
                <About.Item> Version Control(Git)</About.Item>
              </About.Items>
            </About.SubCol>
          </About.Col3>
        </About.Main>
      </About.Container>

      <About.Education>
        <About.EducationContainer>
          {" "}
          <About.Heading>Education</About.Heading>


          <About.EducationContent>
          <About.Col1>
            {" "}
            <About.Items>
              <About.Item>
                {" "}
                Nile University | Giza, Egypt (Sep 2016 –June 2021)
              </About.Item>
              <About.Desc>
                • B.S. in Computer Engineering (GPA 3.84/4.0) <br></br>•
                Recipient of the Nile University's President Full Scholarship
              </About.Desc>
            </About.Items>
          </About.Col1>
          <About.Col2>
            {" "}
            <About.Items>
              <About.Item>
                {" "}
                STEM High School for Boys | Giza, Egypt (Sep 2013 –June 2016)
              </About.Item>
              <About.Desc>• STEM Secondary School (GPA: 4.0)</About.Desc>
            </About.Items>
          </About.Col2>
          </About.EducationContent>
         
        </About.EducationContainer>
      </About.Education>

      <About.Backgroud>
        <About.BackgroudContainer>
          <About.BackgroudGrid>
            <About.Col1></About.Col1>
            <About.Col2>
              <About.BackgroudContent>
                {" "}
                <About.BackgroudTitle>
                 Mahmoud Sallam as a Mentor
                </About.BackgroudTitle>
                <About.Desc>
                  I was lucky enough to start shaping my teaching and leadership
                  skills as a high school student. In 2014, I was selected as
                  the Marcom Chair at STEM Egypt’s Microsoft Tech Club, a
                  Microsoft-affiliated student activity, aiming to advance
                  students’ experience by providing them with essential training
                  in tech and interpersonal skills. My role provided me with a
                  platform to share my experience and knowledge of graphic
                  design, branding, and social media marketing with 270 high
                  school students at STEM Egypt.{" "}
                </About.Desc>
                <About.Desc>
                  Today I'm a Session Lead at Udacity. I'm Leading two sessions
                  with 40 students in each, supporting and teaching the concepts
                  of Nanodegree program such as UX Research, Visual Design,
                  Usability Testing, Prototyping.
                </About.Desc>
              </About.BackgroudContent>
            </About.Col2>
          </About.BackgroudGrid>
        </About.BackgroudContainer>
      </About.Backgroud>

      <About.Achievements>
        <About.AchievementsContainer>
          <About.Heading>Achievements</About.Heading>
          <About.Desc>
            My graduation project Won 8th place out of 233 projects from 19
            countries in Dell Technologies Envision the Future 2021 by leading a
            team of four and developing an intelligent platform for clinics that
            serves and connect doctors and patients using React, Redux, and
            Machine learning technologies.The project acquired a mentorship to
            assist in the marketing and the machine intelligence aspect of the
            project.
          </About.Desc>
          <About.Desc>
          In October 2014, I won 2nd place in WRO (World Robotic Olympiads). It's a competition organized by Ideas Gym under the supervision of the ministry of scientific research. The purpose is to design a functional Robot that can solve certain tasks related to space in a given time.
          </About.Desc>
        </About.AchievementsContainer>
      </About.Achievements>

      <About.Facts>
        <About.FactsContainer>
          <About.FactsContent>
            <About.Col1>
              {" "}
              <About.Heading>Facts</About.Heading>
              <About.Desc>
                As an artist at heart, I love making speed paintings and digital
                artworks. It’s my way to come up with new ideas, fuel my
                motivation, and overcome any burnouts.
              </About.Desc>
              <About.Desc>
                Away from pixel-pushing and coding, I’m a big Yu-Gi-Oh! I’m
                still collecting cards to duel in the real life but If you want
                to challenge me in a duel, ping me in Yugioh Master Duel -
                MSallam. I enjoy watching football matches. I’m a third-left
                Ahly fan. I’m supporting Al Ahly since I was 6 years old.
              </About.Desc>
            </About.Col1>
           
          </About.FactsContent>
        </About.FactsContainer>
      </About.Facts>

      <About.Contact>
        <About.ContactContainer>
          <About.Main>
            <About.Col1>
              <About.BackgroudTitle>Contact</About.BackgroudTitle>{" "}
            </About.Col1>
            <About.Col2>
              <About.Heading>Let's connect</About.Heading>
              <About.Desc>
                Get in touch for opportunities <br></br> or just to say hi! 👋
              </About.Desc>
              <About.SubCol2>
                <About.Items>
                  <About.Item>
                    <About.Link href="mailto:sallam@msallam.me">
                      sallam@msallam.me
                    </About.Link>
                  </About.Item>
                  <About.Item>
                    <About.Link
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.linkedin.com/in/mahmoudmsallam/"
                    >
                      Linkedin
                    </About.Link>
                  </About.Item>
                </About.Items>
              </About.SubCol2>
            </About.Col2>
            <About.Col3>
              {" "}
              <About.SubCol2>
                <About.Heading>EXPERIENCE</About.Heading>
                <About.Items>
                  <About.Item> UX Session Lead at Udacity</About.Item>
                  <About.Item> Software Engineer at IST Networks</About.Item>
                  <About.Item> Product Designer at Nichepharma</About.Item>
                  <About.Item>
                    {" "}
                    Summer Academy Intern at Dell Technologies
                  </About.Item>
                  <About.Item> Data Science Intern at Etisalat Misr</About.Item>
                </About.Items>
              </About.SubCol2>
              <About.SubCol2>
                <About.Items>
                  <About.Item>
                    <About.Link
                      className="resume"
                      target="_blank"
                      href="/Mahmoud-Sallam-Resume.pdf"
                    >
                      Download resume
                    </About.Link>
                  </About.Item>
                </About.Items>
              </About.SubCol2>
            </About.Col3>
          </About.Main>
        </About.ContactContainer>
      </About.Contact>
    </About.Wrapper>
  );
}

export default AboutMe;
