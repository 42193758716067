import React from "react";
import "./paintings-container.css";

function PaintingsContainer() {
  return (
    <div className="paintings-wrapper">
      <div className="paintings-container">
        <div class="box">
          <img alt="my artwork" src="/images/paintings/optimized/1.png" />
          <span>Reincarnation</span>
        </div>
        <div class="box">
          <img alt="my artwork" src="/images/paintings/optimized/2.png" />
          <span>Kurosaki </span>
        </div>
        <div class="box">
          <img alt="my artwork" src="/images/paintings/optimized/3.png" />
          <span>Dexter</span>
        </div>
        <div class="box">
          <img alt="my artwork" src="/images/paintings/optimized/4.png" />
          <span>Eternal</span>
        </div>
      </div>
      <div className="paintings-container">
        <div class="box">
          <img alt="my artwork" src="/images/paintings/optimized/5.png" />
          <span>Last hope</span>
        </div>
        <div class="box">
          <img alt="my artwork" src="/images/paintings/optimized/6.png" />
          <span>DDD</span>
        </div>
        <div class="box">
          <img alt="my artwork" src="/images/paintings/optimized/7.png" />
          <span>Believe in the cards</span>
        </div>
        <div class="box">
          <img alt="my artwork" src="/images/paintings/optimized/8.png" />
          <span>Millennium puzzle</span>
        </div>
      </div>
      <div className="paintings-container">
        <div class="box">
          <img alt="my artwork" src="/images/paintings/optimized/9.png" />
          <span>My companion</span>
        </div>
        <div class="box">
          <img alt="my artwork" src="/images/paintings/optimized/10.png" />
          <span>Chase me</span>
        </div>
        <div class="box">
          <img alt="my artwork" src="/images/paintings/optimized/11.png" />
          <span>Joker</span>
        </div>
        <div class="box">
          <img alt="my artwork" src="/images/paintings/optimized/12.png" />
          <span>Ring of destruction</span>
        </div>
      </div>
      <div className="paintings-container">
        <div class="box">
          <img alt="my artwork" src="/images/paintings/optimized/13.png" />
          <span>Your soul</span>
        </div>
        <div class="box">
          <img alt="my artwork" src="/images/paintings/optimized/14.png" />
          <span>Whale shark</span>
        </div>
        <div class="box">
          <img alt="my artwork" src="/images/paintings/optimized/15.png" />
          <span>All I see is me</span>
        </div>
        <div class="box">
          <img alt="my artwork" src="/images/paintings/optimized/16.png" />
          <span>Zim</span>
        </div>
      </div>
      <div className="paintings-container">
        <div class="box">
          <img alt="my artwork" src="/images/paintings/optimized/17.png" />
          <span>Robot</span>
        </div>
        <div class="box">
          <img alt="my artwork" src="/images/paintings/optimized/18.png" />
          <span>Klaus</span>
        </div>
        <div class="box">
          <img alt="my artwork" src="/images/paintings/optimized/19.png" />
          <span>Doll</span>
        </div>
        <div class="box">
          <img alt="my artwork" src="/images/paintings/optimized/20.png" />
          <span>Back on track</span>
        </div>
      </div>
    </div>
  );
}

export default PaintingsContainer;
