import React from "react";
import "./can-container.css";
import { RiTeamLine } from "react-icons/ri";
import { AiOutlineCoffee } from "react-icons/ai";
import { FaChalkboardTeacher } from "react-icons/fa";
import { BiPaint } from "react-icons/bi";
import { FiPenTool } from "react-icons/fi";
import { BiCodeAlt } from "react-icons/bi";
import { HiOutlineSpeakerphone } from "react-icons/hi";

import Fade from "react-reveal/Fade";

const iconStyle = { color: "var(--project-details-btn-hover)" };

function ICanContainer() {
  return (
    <div className="cans">
      <div className="container">
        {/* <h1 className="skills-title">My competencies</h1> */}
        <h4 className="featured-projects-title">My competencies</h4>

        <div className="cans-content">
          {" "}
          <Fade cascade>
            <div className="can-content">
              {" "}
              <RiTeamLine style={iconStyle} size="48" />
              <h4 className="can-title">Leadership</h4>
              <p className="can-desc">
                I can lead and communicate across different teams, including
                design, research, writing, engineering, and business.
              </p>
            </div>
          </Fade>
          <Fade cascade>
            <div className="can-content">
              {" "}
              <AiOutlineCoffee style={iconStyle} size="48" />{" "}
              <h4 className="can-title">Passion</h4>
              <p className="can-desc">
                I have an enthusiasm for exploring new technologies and am eager
                to learn new things every day.{" "}
              </p>
            </div>
          </Fade>
          <Fade cascade>
            <div className="can-content">
              {" "}
              <HiOutlineSpeakerphone style={iconStyle} size="48" />
              <h4 className="can-title">Talkative</h4>
              <p className="can-desc">
                I love talking with motivated and ambitious people who are
                unafraid of sharing their crazy ideas with the world.
              </p>
            </div>
          </Fade>
          <Fade cascade>
            <div className="can-content">
              {" "}
              <BiPaint style={iconStyle} size="48" />
              <h4 className="can-title">Drawing</h4>
              <p className="can-desc">
                {" "}
                I love making speed paintings and digital artworks. It’s my way
                to come up with new ideas, fuel my motivation, and overcome any
                burnouts.
              </p>
            </div>
          </Fade>
          <Fade cascade>
            <div className="can-content">
              {" "}
              <FiPenTool style={iconStyle} size="48" />
              <h4 className="can-title">Design</h4>
              <p className="can-desc">
                I'm passionate about building digital products that improve the
                lives of people starting from ideation, research, designing, and
                prototyping to implementation.
              </p>
            </div>
          </Fade>
          <Fade cascade>
            <div className="can-content">
              {" "}
              <BiCodeAlt style={iconStyle} size="48" />
              <h4 className="can-title">Coding</h4>
              <p className="can-desc">
                I develop software products and leverage JavaScript to build
                responsive websites, web apps, and interactive features that
                drive business growth and improve UX.
              </p>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
}

export default ICanContainer;
