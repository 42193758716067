import React from "react";
import ProjectsContainer from "../containers/projects-container/ProjectsContainer";
import SkillsContainer from "../containers/skills-container/SkillsContainer";
import ICanContainer from "../containers/I-can-container/ICanContainer";
import FooterContainer from "../containers/footer-container/FooterContainer";
import FeaturedProjectsContainer from "../containers/featured-projects-container/FeaturedProjectsContainer";

import Hero from "../layouts/hero";

function HomePage() {
  return (
    <>
    <Hero/>
      <FeaturedProjectsContainer />
      {/* <ProjectsContainer /> */}
      {/* <BrandsContainer /> */}
      <ICanContainer />
      <SkillsContainer />
      <FooterContainer />
    </>
  );
}

export default HomePage;
