import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  width: 90%;
  max-width: 1040px;
  margin: 0 auto;
  height: 80vh;
  align-items: center;
  @media only screen and (max-width: 40em) {
    height: 74vh;


  }
`;

export const Content = styled.div`
  display: grid;
  padding-top:4em;
  grid-template-columns: 1fr 20px;
  @media only screen and (max-width: 40em) {
    grid-template-columns: unset;
    padding-top:1em;

}
`;

export const Social = styled.div`
  display: grid;
  @media only screen and (max-width: 40em) {
    display: flex;
    justify-content: spcae-between;
    align-items:center;
    margin-top:1.6128em;
    gap:1em;

  }
`;
export const Link = styled.a`
  color: var(--social-links);
  font-size: 1.3rem;
  opacity: 0.6;

  &:hover {
    color: var(--social-links-hover);
    opacity: 1;
  }
`;

export const Pipeline = styled.div`
  height: 70px;
  width: 1px;
  margin-left: 0.5em;
  margin-bottom: 0.3em;
  background-color: var(--social-links);
  @media only screen and (max-width: 40em) {
    width: 40px;
  height: 1px;


  }
`;
export const Watermark = styled.h6`
  font-size: 1.6128rem;
  font-weight: 600;
  margin: 0;
  margin-bottom: 0.8em;
  line-height: 1.1;
  color: var(--social-links-hover);
  @media only screen and (max-width: 40em) {
    font-size: 1.3rem;
  }
`;
export const Roles = styled.h4`
  color: var(--social-links-hover);
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1.7px;
  margin-bottom: 1em;
  font-size: 0.85rem;
  @media (min-width: 475px) {
    font-size: 1rem;
  }
`;

export const Current = styled.p`
  color: var(--featured-projects-title);
  font-weight: 400;
  line-height: 1.4;
  margin-top: 5em;
  font-size:1.125rem;


  @media only screen and (max-width: 40em) {
    margin-top: 3em;
    font-size: 0.95rem;

  }
  span {
    font-weight: 500;
  }
`;

export const Slogn = styled.h1`
  font-size: 1.2rem;
  margin: 0;
  color: var(--hero-title);
  line-height: 1.5;
  font-weight: 600;
  /* font-family: "Merriweather", serif; */

  @media (min-width: 475px) {
    font-size: 1.8rem;
    line-height: 1.2;
  }

  @media (min-width: 750px) {
    font-size: 2.8rem;
    line-height: 1.15;
  }

  span:hover {
    color: var(--hero-subtitle);
    cursor: pointer;
  }
`;

export const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 80vh;
`;
