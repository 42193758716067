import React from "react";
import "./footer-container.css";
import { Link } from "react-router-dom";

function FooterContainer() {
  return (
    <footer>
      <div className="footer-container container">
        <div>
          <h3 className="footer-title">Let's connect</h3>
          <h4 className="footer-subtitle">
            Get in touch for opportunities or just to say hi! 👋
          </h4>
        </div>
        <div>
          {" "}
          <h3 className="footer-title">More</h3>
          <Link to="/paintings" className="footer-link">My paintings</Link>
        </div>
      
     
      </div>
      {/* <div className="">
      <p className="footer-copyrights">
            Mahmoud Sallam© 2021 All Rights Reserved
          </p>
      </div> */}
      
    </footer>
  );
}

export default FooterContainer;
