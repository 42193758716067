import styled from "styled-components";

export const About = {
  Wrapper: styled.div`
    padding-top: 4em;
  `,
  Container: styled.div`
    width: 90%;
    max-width: 1040px;
    margin: 0 auto;
  `,

  Main: styled.div`
    display: grid;
    column-gap: 4em;
    row-gap: 2em;

    @media (min-width: 50em) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  `,

  Facts: styled.div`
    margin-top: 4em;
  `,

  Achievements: styled.div`
    margin-top: 6em;
  `,

  AchievementsContainer: styled.div`
    width: 90%;
    max-width: 1040px;
    margin: 0 auto;
  `,
  FactsContainer: styled.div`
    width: 90%;
    max-width: 1040px;
    margin: 0 auto;
  `,
  Divider: styled.div`
    background-color: #e5e5e5;
    height: 0.05555555555555555rem;
    margin: 0 auto;
  `,

  FactsContent: styled.div``,

  Education: styled.div`
    padding: 4em 0;

  `,
  EducationContainer: styled.div`
    width: 90%;
    max-width: 1040px;
    margin: 0 auto;
  `,

  EducationContent: styled.div`
    display: flex;
    flex-direction: column;
    column-gap: 6em;
    @media (min-width: 50em) {
      flex-direction: row;
    }
  `,

  Col1: styled.div``,

  Img: styled.img`
    object-fit: cover;
    max-width: 100%;
  `,

  SubCol: styled.div`
    margin-bottom: 2.5em;
    background-color: #f9fafe;
    padding: 1.3em;
  `,

  SubCol2: styled.div`
    margin-bottom: 2.5em;
  `,

  Heading: styled.h3`
    font-size: 0.9rem;
    color: var(--header-links);
    text-transform: uppercase;
    font-weight: 500;
  `,

  Desc: styled.p`
    font-weight: 300;
    line-height: 1.9128;
    font-size: 0.9rem;
    text-align: left;
    color: var(--card-title);
  `,

  Items: styled.ul`
    list-style: none;
    padding: 0;
  `,

  Item: styled.li`
    font-weight: 500;
    font-size: 0.9rem;
    margin-bottom: 0.6128em;
  `,
  Col2: styled.div``,
  Col3: styled.div``,

  Backgroud: styled.div`
    background-image: url(/images/teaching4.png);
    background-color: var(--header-logo);
    background-size: cover;
    padding: 8em 0;
  `,

  BackgroudContainer: styled.div`
    width: 90%;
    max-width: 1040px;
    margin: 0 auto;
  `,

  BackgroudGrid: styled.div`
    display: grid;
    column-gap: 10em;

    @media (min-width: 60em) {
      grid-template-columns: 1fr 2fr;
    }
  `,

  BackgroudContent: styled.div`
    background-color: #fff;
    padding: 1.6em 2em;
    @media (min-width: 60em) {
      padding: 2em 4em;
    }
  `,

  BackgroudTitle: styled.h2`
    font-size: 1.3rem;
    line-height: 1.3;

    @media (min-width: 60em) {
      font-size: 1.6128rem;
      line-height: 1.5;
    }
  `,

  Contact: styled.div`
    padding: 12em 0;
    margin-top: 8em;
    background-color: var(--header-logo);
    color: #fff;
    li {
      font-weight: 300;
      font-size: 0.85rem;
    }
    a {
      color: #faf9f9;
    }

    a.resume {
      text-decoration: underline;
    }
    p {
      color: #fff;
    }
  `,

  Link: styled.a`
    text-decoration: none;
  `,

  ContactContainer: styled.div`
    width: 90%;
    max-width: 1040px;
    margin: 0 auto;
  `,
};
