import React from "react";

import { FeaturedCard } from "./style";
import {Link} from 'react-router-dom'

function Card({ featuredThumbnail, title, category, to }) {
  return (
    <FeaturedCard.Card>
      <FeaturedCard.Thumbnail>
        <Link to={`/projects/${to}`}>
          <FeaturedCard.Img src={featuredThumbnail} alt="featured project" />
        </Link>
      </FeaturedCard.Thumbnail>

      <FeaturedCard.Content>
        {" "}
        <h2>{title}</h2>
        <p>{category}</p>
      </FeaturedCard.Content>

      <Link to={`/projects/${to}`}>
        <i className="fas fa-arrow-right"></i>
        <FeaturedCard.Button></FeaturedCard.Button>
      </Link>
    </FeaturedCard.Card>
  );
}

export default Card;
