import React from "react";
import {
  Container,
  Content,
  Roles,
  Slogn,
  Image,
  Social,
  Link,
  Pipeline,
  Watermark,
  Current
} from "./style";

export default function Hero({ toggleTheme, theme, children, ...restProps }) {
  return <Container {...restProps}> {children}</Container>;
}

Hero.Content = function HeroContent({ children, ...restProps }) {
  return <Content {...restProps}>{children}</Content>;
};
Hero.Social = function HeroSocial({ children, ...restProps }) {
  return <Social {...restProps}>{children}</Social>;
};

Hero.Link = function HeroLink({ children, ...restProps }) {
  return <Link {...restProps}>{children}</Link>;
};
Hero.Pipeline = function HeroPipeline({ ...restProps }) {
  return <Pipeline {...restProps} />;
};
Hero.Watermark = function HeroWatermark({ children,...restProps }) {
  return <Watermark {...restProps}>{children}</Watermark>;
};

Hero.Image = function HeroImage({ ...restProps }) {
  return <Image {...restProps} />;
};
Hero.Roles = function HeroRoles({ children, ...restProps }) {
  return <Roles {...restProps}>{children}</Roles>;
};
Hero.Current = function HeroRoles({ children, ...restProps }) {
  return <Current {...restProps}>{children}</Current>;
};
Hero.Slogan = function HeroSlogan({ children, ...restProps }) {
  return <Slogn {...restProps}>{children}</Slogn>;
};
