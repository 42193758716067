import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import HomePage from "./pages/HomePage";
import AboutMePage from "./pages/AboutMePage";
import PaintingsPage from "./pages/PaintingsPage";
import ProjectDetailsPage from "./pages/ProjectDetailsPage";

import NavBar from "./components/NavBar";

function App() {
  const [theme, setTheme] = useState("light");

  const setMode = (mode) => {
    window.localStorage.setItem("theme", mode);
    setTheme(mode);
  };

  const toggleTheme = () =>
    theme === "light" ? setMode("dark") : setMode("light");

  useEffect(
    () => {
      const localTheme = window.localStorage.getItem("theme");
      localTheme && setTheme(localTheme);
    },
    [theme],
    toggleTheme
  );

  return (
    <Router>
      <div className={`app ${theme}`}>
        <NavBar />

        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route path="/about-me">
            <AboutMePage />
          </Route>
          <Route path="/projects/:id">
            <ProjectDetailsPage />
          </Route>
          <Route path="/paintings">
            {" "}
            <PaintingsPage />
          </Route>
          <Route path="*">Error 404</Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;