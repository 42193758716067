import React from "react";
import FooterContainer from "../containers/footer-container/FooterContainer";
import AboutMe from "../layouts/AboutMe";
function AboutMePage() {
  return (
    <>
      <AboutMe />
      {/* <FooterContainer /> */}
    </>
  );
}

export default AboutMePage;
