import React from "react";
import Card from "../../components/card/Card";
import "./projects-container.css";

function ProjectsContainer() {
  return (
    <div className="projects">
      <div className="container">
        {" "}
        <h4 className="featured-projects-title">MORE WORK</h4>
      </div>
      <div className="container projects-container">
        <Card
          title="Evaluator App"
          category="Visual Design , Frontend Development"
          thumbnail="/images/projects/tacitapp.png"
        />
        <Card
          title="Hive SDK"
          category="UX/UI Design , Design System"
          thumbnail="/images/projects/hive.png"
        />
        <Card
          title="Chicken Eleven"
          category="UX/UI Design, Branding"
          thumbnail="/images/projects/chicken.png"
        />
        <Card
          title="FoodOverFlow"
          category="UX/UI Design"
          thumbnail="/images/projects/foodoverflow.png"
        />
        <Card
          title="Nichepharma Website"
          category="Frontend Development"
          thumbnail="/images/projects/nichepharma.png"
        />
      </div>
    </div>
  );
}

export default ProjectsContainer;
