import styled from "styled-components";

export const FeaturedCard = {
  Card: styled.div`
    /* border: 1px solid var(--card-border); */
    border-radius: 8px;
    position: relative;
    height: 300px;
    background-color: var(--featured-card-background);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(50, 50, 93, 0.01) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.08) 0px 30px 60px -30px;
    transition: 0.3s ease;

    svg.fa-arrow-right{
      opacity: 0;
      font-size: 1.2rem;
      color: #fff;
      will-change: transform;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
      z-index: 100;
      position: absolute;
      right: 2em;
      bottom: 1.5em;
    }

    &:hover {
      box-shadow: rgba(50, 50, 93, 0.1) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.2) 0px 30px 60px -30px;
      border: none;
      svg.fa-arrow-right{
        opacity: 1;
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
        cursor: pointer;
      }

      Button {
        transform: scale(5);
      }
      @media (min-width: 650px) {
        Button {
          transform: scale(22);
        }
        h2 {
          color: var(--featured-card-hover-title);
        }
        p {
          z-index: 99;
          color: var(--featured-card-hover-subtitle);
        }
      }
      @media (min-width: 900px) {
        Button {
          transform: scale(30);
        }
      }
    }

    @media (min-width: 650px) {
      flex-direction: row;
      align-items: center;
    }
  `,

  Button: styled.button`
    position: absolute;
    right: 1em;
    bottom: 1em;
    width: 30px;
    height: 30px;
    background-color: var(--featured-card-button);
    border: none;
    border-radius: 30px;
    cursor: pointer;
    outline: none;
    transition: all 0.3s ease;
    &:hover {
      transform: scale(5);
    }
    @media (min-width: 650px) {
      &:hover {
        transform: scale(22);
      }
    }

    @media (min-width: 900px) {
      &:hover {
        transform: scale(30);
      }
    }
  `,
  Thumbnail: styled.div`
    @media (min-width: 650px) {
      width: 60%;
    }
  `,
  Img: styled.img`
    object-fit: cover;
    height: 200px;
    @media (min-width: 650px) {
      height: 310px;
    }
  `,

  Content: styled.div`
    position: relative;
    padding: 1em 2em;
    text-align: left;
    z-index: 100;
    @media (min-width: 650px) {
      width: 35%;
      text-align: left;
    }

    h2 {
      font-size: 1.2rem;
      font-weight: 700;
      margin-bottom: 0;
      color: var(--featured-card-title);
      @media (min-width: 650px) {
        font-size: 2.5rem;
        line-height: 1.1;
        margin-bottom: 0.3em;
      }
    }
    p {
      color: var(--featured-card-subtitle);
      font-size: 0.85rem;
      margin-top: 0.4em;
      @media (min-width: 650px) {
        margin-top: 0.8em;
      }
    }
  `,
};
