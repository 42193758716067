import React from "react";
import "./skills-container.css";

import Fade from "react-reveal/Fade";

function SkillsContainer() {
  return (
    <div className="skills">
      <div className="container">
        <h1 className="skills-title">Skills</h1>

        <div className="skills-content">
          {" "}
          <Fade cascade>
            <div className="skill-content">
              <h2 className="skills-subtitle">Research</h2>
              <p className="skills-desc">Surveys</p>
              <p className="skills-desc">Interviews</p>
              <p className="skills-desc">Persona</p>
              <p className="skills-desc">Affinity Mapping</p>
              <p className="skills-desc">Usability Testing</p>
              <p className="skills-desc">A/B Testing</p>
              <p className="skills-desc">Contextual Inquiry</p>
            </div>
          </Fade>
          <Fade cascade>
            <div className="skill-content">
              <h2 className="skills-subtitle">Design</h2>
              <p className="skills-desc">ِAdobe Photoshop</p>
              <p className="skills-desc">ِAdobe XD</p>
              <p className="skills-desc">ِAdobe Illustrator</p>
              <p className="skills-desc">ِFigma</p>
              <p className="skills-desc">Balsamiq</p>
              <p className="skills-desc">Wireframing</p>
              <p className="skills-desc">Paper Prototyping</p>
              <p className="skills-desc">Interactive Prototyping</p>
            </div>
          </Fade>
          <Fade cascade>
            <div className="skill-content">
              <h2 className="skills-subtitle">Development</h2>
              <p className="skills-desc">ِHTML/CSS/JavaScript</p>
              <p className="skills-desc">React.js</p>
              <p className="skills-desc">Redux</p>
              <p className="skills-desc">Responsive Design</p>
              <p className="skills-desc">Styled Components</p>
              <p className="skills-desc">Material UI</p>
              <p className="skills-desc">Bootstrap</p>
              <p className="skills-desc">Tailwind CSS</p>
              <p className="skills-desc">GraphQL</p>
              <p className="skills-desc">Apollo</p>
              <p className="skills-desc">CMS(Strapi)</p>
              <p className="skills-desc">Google Cloud(Firebase)</p>
              <p className="skills-desc">Version Control(Git)</p>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
}

export default SkillsContainer;
