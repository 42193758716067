import React from "react";
import "./featured-projects-container.css";
import useFetch from "../../hooks/useFetch";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import FeaturedCard  from "../../components/FeaturedCard";

// .slice(0)
// .reverse()

function FeaturedProjects() {
  const { loading, error, data } = useFetch();

  if (loading)
    return (
      <div className="container loader">
        <Loader
          type="Rings"
          color="#05F4B7"
          height={100}
          width={100}
          timeout={8000} //3 secs
        />
      </div>
    );
  if (error) return <p>Error!</p>;
  // console.log(data);
  return (
    <div className="featured-projects-wrapper">
      <div className="container">
        <h4 className="featured-projects-title">MY FEATURED WORK</h4>

        <div className="featured-projects">
          {data.map((project) => (
            <FeaturedCard
              key={project.fields.id}
              to={project.fields.id}
              title={project.fields.title}
              category={project.fields.category}
              featuredThumbnail={
                project.fields.featuredThumbnail.fields.file.url
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default FeaturedProjects;
