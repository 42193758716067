import React from "react";
import ProjectDetailsContainer from "../containers/project-details-container/ProjectDetailsContainer";
import FooterContainer from "../containers/footer-container/FooterContainer";

function ProjectDetailsPage() {
  return (
    <>
      <ProjectDetailsContainer />
      <FooterContainer />
    </>
  );
}

export default ProjectDetailsPage;
