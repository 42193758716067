import styled from "styled-components";
import { Link } from "react-router-dom";

export const Navbar = {
  Wrapper: styled.nav`
    flex: 1;
    padding-top: 2em;
    width: 90%;
    max-width: 1040px;
    margin: 0 auto;


    align-self: flex-start;

    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: white;

    @media only screen and (max-width: 40em) {
      top: 0;
      z-index: 100;

    }
  `,
  Logo: styled(Link)`
    text-decoration: none;
    color: var(--header-logo);
    font-weight: 700;
    font-size: 1.45rem;
    letter-spacing: -1px;
    @media only screen and (max-width: 40em) {
      padding: 0.5em 0em;
      font-size: 1.3rem;

    }
  `,
  Items: styled.ul`
    display: flex;
    list-style: none;

    @media only screen and (max-width: 40em) {
      position: fixed;
      right: 0;
      top: 0;
      margin: 0;

      height: 100%;

      flex-direction: column;
      z-index: 1000;

      background-color: #371bb1;
      padding: 1rem 2rem;
      transition: 0.2s ease-out;
      padding-top:4em;


      transform: ${({ openDrawer }) =>
        openDrawer ? `translateX(0)` : `translateX(100%)`};
    }
  `,
  Item: styled(Link)`
    padding: 0 1rem;
    text-decoration: none;

    cursor: pointer;
    color: var(--header-links);
    cursor: pointer;
    font-weight: ${({ active }) => (active === true ? "600" : "normal")};
    color: ${({ active }) =>
      active === true ? "var(--header-links-hover)" : "normal"};

    &:hover {
      color: var(--header-links-hover);
      font-weight: 500;
    }

    @media only screen and (max-width: 40em) {
      padding: 1rem 0;
      color: white;
      &:hover {
        color: white;
      }
    }
  `,
};

export const HamburgerButton = {
  Wrapper: styled.button`
    height: 3rem;
    width: 3rem;
    position: relative;
    font-size: 12px;

    display: none;

    @media only screen and (max-width: 40em) {
      display: block;
    }

    /* Remove default button styles */
    border: none;
    background: transparent;
    outline: none;

    cursor: pointer;

    &:after {
      content: "";
      display: block;
      position: absolute;
      height: 150%;
      width: 150%;
      top: -25%;
      left: -25%;
    }
  `,
  Lines: styled.div`
    top: 50%;
    margin-top: -0.125em;

    &,
    &:after,
    &:before {
      /* Create lines */
      height: 2px;
      pointer-events: none;
      display: block;
      content: "";
      width: 30px;
      background-color: black;
      position: absolute;
    }

    &:after {
      /* Move bottom line below center line */
      top: -0.5rem;
    }

    &:before {
      /* Move top line on top of center line */
      top: 0.5rem;
    }
  `,
};
