import React from "react";
import useFetch from "../../hooks/useFetch";
import { useParams } from "react-router-dom";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import "./project-details-container.css"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
function ProjectDetailsContainer() {
  const { loading, error, data } = useFetch();
  const { id } = useParams();

  if (loading) return  <div className="container loader">
  <Loader
    type="Rings"
    color="#05F4B7"
    height={100}
    width={100}
    timeout={8000} //3 secs
  />
</div>;
  if (error) return <p>Error!</p>;
  const filterProjects = data.filter((project) => project.fields.id === id);
  const filteredProject = filterProjects[0].fields;

  const renderOptions = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        // render the EMBEDDED_ASSET as you need
        console.log(node.data);
        return (
          <img
            src={`https://${node.data.target.fields.file.url}`}
            alt={node.data.target.fields.description}
          />
        );
      },
    },
  };
  // console.log(filteredProject.featuredThumbnail.fields.file.url);

  return (
    <div className="project-details-wrapper">
      <div
        style={{
          backgroundImage: `url(
           ${filteredProject.featuredThumbnail.fields.file.url}
          )`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          // backgroundAttachment:"fixed",
          height: "80vh",
        }}
        className="details-banner"
      ></div>
      <div className="project-details-container container">
        {documentToReactComponents(filteredProject.details, renderOptions)}
      </div>
    
      <div></div>
    </div>
  );
}

export default ProjectDetailsContainer;
