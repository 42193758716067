import React from "react";
import PaintingsContainer from "../containers/paintings-container/PaintingsContainer";
import FooterContainer from "../containers/footer-container/FooterContainer";

function PaintingsPage() {
  return (
    <>
      {" "}
      <PaintingsContainer />
      {/* <FooterContainer /> */}
    </>
  );
}

export default PaintingsPage;
