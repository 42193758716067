import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Navbar, HamburgerButton } from "./style";

function NavBar() {
  const [openDrawer, toggleDrawer] = useState(false);
  const drawerRef = useRef(null);

  useEffect(() => {
    /* Close the drawer when the user clicks outside of it */
    const closeDrawer = (event) => {
      if (drawerRef.current && drawerRef.current.contains(event.target)) {
        return;
      }

      toggleDrawer(false);
    };

    document.addEventListener("mousedown", closeDrawer);
    return () => document.removeEventListener("mousedown", closeDrawer);
  }, []);

  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");

  return (
    <Navbar.Wrapper>
      <Navbar.Logo to="/">Sallam.</Navbar.Logo>

      <HamburgerButton.Wrapper onClick={() => toggleDrawer(true)}>
        <HamburgerButton.Lines />
      </HamburgerButton.Wrapper>

      <Navbar.Items ref={drawerRef} openDrawer={openDrawer}>
        <Navbar.Item active={splitLocation[1] === "/" ? true : false} to="/">
          Home
        </Navbar.Item>
        <Navbar.Item
          active={splitLocation[1] === "paintings" ? true : false}
          to="/paintings"
        >
          Paintings
        </Navbar.Item>
        <Navbar.Item
          active={splitLocation[1] === "about-me" ? true : false}
          to="/about-me"
        >
          About me
        </Navbar.Item>
        <Navbar.Item target="_blank" to="/Mahmoud-Sallam-Resume.pdf">
          Resume
        </Navbar.Item>
      </Navbar.Items>
    </Navbar.Wrapper>
  );
}

export default NavBar;
