import React from "react";
import Hero from "../../components/hero";
import { FaLinkedinIn, FaGithub } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
function HeroLayout() {
  return (
    <Hero>
      <Hero.Content>
        <div>
          {" "}
          {/* <Hero.Roles>[PRODUCT DESIGNER/FRONTEND DEVELOPER]</Hero.Roles> */}
          <Hero.Watermark>[PRODUCT DESIGNER / FRONTEND DEVELOPER]</Hero.Watermark>
          <Hero.Slogan>
            {" "}
            I craft user-centric and delightful experiences from{" "}
            <span>Concept</span> to <span>Research</span> to <span>Design</span>{" "}
            to <span>Frontend Development</span>.
          </Hero.Slogan>
        </div>
        <Hero.Social>
          <Hero.Pipeline />
          <Hero.Link
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/in/mahmoudmsallam/"
          >
            {" "}
            <FaLinkedinIn />
          </Hero.Link>
          <Hero.Link
            target="_blank"
            rel="noreferrer"
            href="https://github.com/MahmoudSallam1"
          >
            {" "}
            <FaGithub />
          </Hero.Link>
          <Hero.Link href="mailto:sallam@msallam.me">
            {" "}
            <MdEmail />
          </Hero.Link>
        </Hero.Social>
        <Hero.Current>
          — <br />
          UX Session Lead at <span>Udacity</span> <br />
          Software Engineer at <span>IST Networks</span>
        </Hero.Current>
      </Hero.Content>

      {/* <Hero.Image src="/images/sallam2.jpg" alt="Mahmoud Sallam" /> */}
    </Hero>
  );
}

export default HeroLayout;
